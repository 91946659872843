import {useSelector} from "react-redux";

function Promo() {

    const promo = useSelector(state => state.tournament.description.promo)
    const lang = useSelector(state => state.user.lang);
    const {html,img} = promo[lang]
    return (
        <div className="promo">
            <div className="promo__image">
                {img && <img alt="promo" src={img}/>}
            </div>
            <div className="promo__content" dangerouslySetInnerHTML={{__html: html}}/>
        </div>)
}

export default Promo