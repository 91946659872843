import Table from "./Table/Table";

function TournamentTable ({data = []}) {
    return(
        <div className="table__container">
            <Table tableData={data} />
        </div>
    )
};

export default TournamentTable;
