import React from 'react';
import {useTranslation} from "react-i18next";
import {createTableRow} from "./TableElements";
import {useSelector, useDispatch} from "react-redux";

const Table = ({tableData}) => {
    const {tableRows, role, tableName, titles} = tableData
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const userRateValue = useSelector(state => state.user.currencyRate);

    return (
        <div className="table__content">
            <div className="table__header">
                {
                    titles?.map((header, index) => {
                    return (
                        <div
                            className="title"
                            key={index}>
                            {t(`table.${header}`)}
                        </div>
                    )
                })
                }
            </div>
            <div className="table__body">
                <div className="table__body--wrapper">
                    {tableRows?.map((row, rowIndex) => {
                        if (row.selected) row.cells['table_playerId'] = t(`table.table_you`)
                        return createTableRow(role, tableName, row, rowIndex, userRateValue, dispatch)
                    })}
                </div>
            </div>

        </div>
    );

};

export default Table;
