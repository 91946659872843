import './App.scss';
import GameScreen from "./game/components/GameScreen/GameScreen";
import LoaderGlobal from "./game/components/Loaders/LoaderGlobal";
import {useSelector} from "react-redux";
import {useEffect} from "react";

const App = ({changeLanguage}) => {
    const id = useSelector(state => state.user.id);
    const lang = useSelector(state => state.user.lang);
    const gameLoader = useSelector(state => state.game.gameLoader);
    const notification = useSelector(state => state.game.notification);

    useEffect(() => {
        if(lang) {
            changeLanguage(lang)
        }
    }, [lang, changeLanguage]);

    return <div className="app">
        <LoaderGlobal isActive={gameLoader} notification={notification}/>
        {(!gameLoader && id) && <div className='app__wrapper'>
            <GameScreen/>
        </div>}

    </div>

}
export default App;