import TOURNAMENT from "../actions/tournament/types";
import GAME from "../actions/game/types";
import UserSocket from "../sockets/userSocket";

const initialState = {
    description: [],
    userPlace: {},
    leaderBoard: {},
    prevLeaderBoard: {},
    hasTournament: false
};

const reducer = (state = initialState, action) => {
    const socket = UserSocket.socket

    switch (action.type) {

        case TOURNAMENT.GET_FULL_DATA:
            return {
                ...state,
                ...action.payload
            };

        case TOURNAMENT.GET_PREVIOUS_LEADERBOARD:
            socket.emit(GAME.EVENT, {action: action.type})
            return state

        case TOURNAMENT.UPDATE_PREVIOUS_LEADERBOARD:
            return {
                ...state,
                prevLeaderBoard: action.payload
            }

        case TOURNAMENT.UPDATE_LEADERBOARD:
            return {
                ...state,
                ...action.payload
            };

        case TOURNAMENT.UPDATE:
            return {
                ...state,
                ...action.payload
            };

        case TOURNAMENT.CLEAR_TOURNAMENT:
            return {
                description: [],
                userPlace: {},
                leaderBoard: {},
                hasTournament: false,
                prevLeaderBoard: {},
            };


        default:
            return state;
    }
};

export default reducer;
