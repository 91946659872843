import { useEffect, useState } from "react";
import Loader from "../Loaders/Spinner";
import Tabs from "../Tabs/Tabs";
import MappedTables from "../Tables/tablemapping";
import Promo from "../Promo/Promo";
import {useDispatch, useSelector} from "react-redux";
import {tournamentGetPreviousLeaderBoard} from "../../../actions/tournament/actions";

function TableContainer({ tabItems, tableData }) {
    const isMobile = useSelector(state => state.user.isMobile);
    const dispatch = useDispatch();
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const [selectedSubTabIndex, setSelectedSubTabIndex] = useState(0);
    const [initialTabItems, setInitialTabItems] = useState(tabItems);

    // Handle Tab Change
    const handleTabChange = (newTabIndex) => {
        setSelectedTabIndex(newTabIndex);

        if (tabItems[selectedTabIndex] === 'tabs.tournament') {
            setSelectedSubTabIndex(0)
        }
    };

    // Handle Sub-Tab Change
    const handleSubTabChange = (newTabIndex) => {
        if (tabItems[selectedTabIndex] === 'tabs.tournament' && newTabIndex === 1) {
            dispatch(tournamentGetPreviousLeaderBoard())
        }
        setSelectedSubTabIndex(newTabIndex);
    };

    // Sync tab items on length change
    useEffect(() => {
        if (tabItems.length !== initialTabItems.length) {
            setSelectedTabIndex(0);
            setInitialTabItems(tabItems);
        }
    }, [tabItems.length, initialTabItems.length, tabItems]);
    // Render the table based on the selected tab and data
    const renderTable = () => {
        const TableComponent = MappedTables[tableData[selectedTabIndex]?.role];
        return tableData[selectedTabIndex]?.tableRows?.length > 0 ? <TableComponent data={tableData[selectedTabIndex]} /> : <Loader />;
    };
    // Render sub-tabs for tournament
    const renderSubTabs = () => {
        if (tabItems[selectedTabIndex] === 'tabs.tournament') {
            const leaderBoard = tableData[selectedTabIndex][selectedSubTabIndex];
            const isEmpty = leaderBoard?.tableRows?.length === 0;
            const TableComponent = MappedTables['leaderBoard'];
            const title = selectedSubTabIndex === 1 ?
                `${new Date(leaderBoard?.dateRange?.[0]).toLocaleDateString('en-GB', {
                    month: 'numeric',
                    day: 'numeric',
                })} -  ${new Date(leaderBoard?.dateRange?.[1]).toLocaleDateString('en-GB', {
                    month: 'numeric',
                    day: 'numeric',
                })}` : 'tabs.previous'
            return (
                <div className='sub-table'>
                    <Tabs
                        toggle={handleSubTabChange}
                        selected={selectedSubTabIndex + 1}
                        tabItems={['tabs.current', title]}
                    />
                    {isEmpty || Object.values(leaderBoard).length === 0 ? <Loader /> : <TableComponent data={leaderBoard} />}
                </div>
            );
        }
        return null;
    };

    // Render promo for information tab
    const renderPromo = () => {
        if (tabItems[selectedTabIndex] === 'tabs.information') {
            return <Promo />;
        }
        return null;
    };
    // Render table for other relevant tabs
    const renderOtherTables = () => {
        const otherTabs = ['tabs.information', 'tabs.tournament'];

        if (!otherTabs.includes(tabItems[selectedTabIndex])) {
            return renderTable();
        }
        return null;
    };

    const renderTabs = () => {
        let initialTitles = [...tabItems];
        if (isMobile) {
            if (tabItems[selectedTabIndex] === 'tabs.previous') {
                const data = tableData?.[selectedTabIndex]
                initialTitles[selectedTabIndex] = `${new Date(data?.dateRange?.[0]).toLocaleDateString('en-GB', {
                    month: 'numeric',
                    day: 'numeric',
                })}  -  ${new Date(data?.dateRange?.[1]).toLocaleDateString('en-GB', {
                    month: 'numeric',
                    day: 'numeric',
                })}`
            } else {
                initialTitles = tabItems;
            }
        }
        return  <Tabs
            toggle={handleTabChange}
            selected={selectedTabIndex + 1}
            tabItems= {initialTitles}
        />
    }

    return (
        <div className="table table__wrapper">
            {renderTabs()}
            {renderSubTabs()}
            {renderOtherTables()}
            {renderPromo()}
        </div>
    );
}

export default TableContainer;
